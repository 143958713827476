<template>
  <div class="route-specification">
    <!-- <Map :config="bigMapConfig" /> -->
    <!-- <folder-modal ref="folder" :config="folderConfig">
      <div class="wrapper">
        <add-air-line-project @click.native="addProject" />
        <search-input @search="fetchProject" />
        <span class="list-title">最新</span>
        <div class="list">
          <air-line-project :info="air" v-for="(air, index) in airLineList" :key="index" />
        </div>
      </div>
    </folder-modal> -->
    <Modal v-model="showModal"
           @on-cancel="cancel"
           :footer-hide="true"
           :styles="{padding:'0',width:($public?'360px':'360px')}"
           :mask-closable="false">
      <add-project-box ref="box"
                       :config="projectConfig" />
    </Modal>
    <Spin size="large"
          fix
          v-if="spinShow"></Spin>
  </div>
</template>

<script>
import Map from '@/components/statistics/commonComponents/map.vue';
import AddProjectBox from '@/components/airLine/commonComponents/add-project-box.vue';
import Api from '@/utils/api.js';

export default {
  name: 'routeSpecification',
  components: {
    Map,
    AddProjectBox,
  },
  created() {
    // this.fetch3DList()
    this.spinShow = false;
    this.showModal = true;
    this.projectConfig.team_id = this.$route.query.team_id;
    this.projectConfig.dir_id = this.$route.query.dir_id;
  },
  data() {
    return {
      projectConfig: {
        model3DList: [],
        team_id: 0,
        dir_id: -1,
      },
      spinShow: true,
      bigMapConfig: {
        tools: ['hierarchy', 'zoomIn', 'zoomOut'],
      },
      showModal: false,
      folderConfig: {
        width: 3.4,
        backgroundColor: '#FAFAFA',
        direction: 'left',
      },
      styles: {
        padding: 0,
        width: '360px',
      },
      airLineList: [
        {
          projectName: '小红点航线1',
          date: '2020年1月10日 12:16:54',
          location:
            '湖南省天心区城南路贺龙体育馆旁边的大狗科技的左边的小卖部的后面的角落',
          actions: [
            {
              text: '编辑',
              cb: (params) => {
                this.$router.push({
                  path: '/create/air-line/application',
                  query: {
                    type: 'line',
                    method: 'edit',
                    taskName: '213',
                  },
                });
              },
            },
            {
              text: '删除',
              cb: (params) => {
                console.log('delete');
              },
            },
          ],
        },
        {
          projectName: '小红点航线1',
          date: '2020年1月10日 12:16:54',
          location:
            '湖南省天心区城南路贺龙体育馆旁边的大狗科技的左边的小卖部的后面的角落',
          actions: [
            {
              text: '编辑',
              cb: (params) => {
                this.$router.push({
                  path: '/create/air-line/application',
                  query: {
                    type: 'line',
                    method: 'edit',
                    taskName: '123',
                  },
                });
              },
            },
            {
              text: '删除',
              cb: (params) => {
                console.log('delete');
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    // 获取3d模型id数据
    fetch3DList() {
      this.projectConfig.team_id = this.$route.query.team_id;
      this.$post(Api.get3DModelList())
        .then((res) => {
          if (res.data) {
            this.projectConfig.model3DList = res.data.map((ele) => {
              return {
                label: ele.modelname,
                value: ele.id,
              };
            });
          } else {
            // this.$notice.error({
            //   title: "3D模型接口异常,返回无数据"
            // });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '3D模型接口异常,返回无数据',
            desc: err.toString(),
          });
        })
        .finally(() => {
          this.spinShow = false;
          this.showModal = true;
        });
    },
    fetchProject(key) {
      console.log(key);
    },
    // 唤醒添加任务
    addProject() {
      this.showModal = true;
      this.$refs.folder.triggle();
    },
    cancel() {
      // this.$refs.box.resetStatus();
      this.$router.push('/index/project');
    },
  },
};
</script>

<style lang="scss" scoped>
.route-specification {
  width: 100%;
  height: 100%;
  position: relative;
  //.wrapper {
  //  max-height: 100%;
  //  display: flex;
  //  flex-direction: column;
  //  position: relative;
  //  /deep/ {
  //    .list {
  //      flex: 1;
  //      overflow-x: hidden;
  //      overflow-y: auto;
  //    }
  //    .list-title {
  //      margin-top: pcRem(30);
  //      margin-bottom: pcRem(10);
  //      color: #333;
  //      font-size: pcRem(18);
  //      margin-bottom: pcRem(10);
  //      display: inline-block;
  //    }
  //  }
  //}
}
</style>
