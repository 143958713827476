<template>
  <div class="add-project-box">
    <div class="box-item">
      <div class="box-item margin_bottom">
        <div class="title">新建任务</div>
        <Input autofocus
               placeholder="请输入任务名"
               v-model.trim="taskName"
               show-word-limit
               maxlength="30" />
        <!-- <Input autofocus
               placeholder="请输入任务名"
               v-model.trim="taskName" /> -->
      </div>
      <div class="title">选择任务类型</div>
      <div style="width: 100%; height: 90px">
        <div class="content">
          <div class="upLoading"
               :style="upLoadingStatus == 1 ? LoadingStyle_pr : LoadingStyle"
               @click="upLoadingStatus = 1">
            <img :src="
                upLoadingStatus == 1
                  ? icons.home_app_route_pr
                  : icons.home_app_route
              "
                 alt="" />
            <span :style="upLoadingStatus == 1 ? 'color:#fff' : 'color:#2196F3'">航线任务</span>
          </div>
          <div class="task"
               :style="upLoadingStatus == 2 ? LoadingStyle_pr : LoadingStyle"
               @click="upLoadingStatus = 2">
            <img :src="
                upLoadingStatus == 2
                  ? icons.home_app_modeling_pr
                  : icons.home_app_modeling
              "
                 alt="" />
            <span :style="upLoadingStatus == 2 ? 'color:#fff' : 'color:#2196F3'">建图任务</span>
          </div>
          <!-- <div class="upLoading"
               v-if="$public"
               :style="upLoadingStatus == 3 ? LoadingStyle_pr : LoadingStyle"
               @click="upLoadingStatus = 3">
            <img :src="
                upLoadingStatus == 3
                  ? icons.home_app_route_pr
                  : icons.home_app_route"
                 alt="" />
            <span :style="upLoadingStatus == 3 ? 'color:#fff' : 'color:#2196F3'">3D航线</span>
          </div> -->
        </div>
      </div>
      <div class="flex">
        <Button type="primary"
                @click.stop="createApp">继续</Button>
      </div>
    </div>
  </div>
</template>

<script>
import AirRoute from '@/assets/img/statistics/air_route.png';
import Slam from '@/assets/img/statistics/slam.png';
import home_app_modeling from '@/assets/img/Icon/home_app_modeling.png';
import home_app_modeling_pr from '@/assets/img/Icon/home_app_modeling_pr.png';
import home_app_route from '@/assets/img/Icon/home_app_route.png';
import home_app_route_pr from '@/assets/img/Icon/home_app_route_pr.png';
export default {
  name: 'addProjectBox',

  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      icons: {
        airRoute: AirRoute,
        slam: Slam,
        home_app_modeling,
        home_app_modeling_pr,
        home_app_route,
        home_app_route_pr,
      },
      LoadingStyle: {
        backgroundColor: '#fff',
        border: '1px solid #DEDEDE',
      },
      LoadingStyle_pr: {
        backgroundColor: '#4C98FF',
        border: '1px solid #4C98FF',
      },
      taskName: '',
      taskType: '',
      task3DModel: '',
      task3DList: [],
      upLoadingStatus: 1, //1,航线  2,建图
      teamID: 0, // index传入的team_id
      dirID: -1, // index传入的dir_id
    };
  },

  watch: {
    config: {
      deep: true,
      handler: function (val) {
        this.task3DList = val.model3DList;
        // this.teamID = val.team_id;
      },
    },
  },

  methods: {
    // 重置状态
    resetStatus() {
      this.taskName = '';
    },
    // 选择任务类型
    chooseLineType(type) {
      this.taskType = type;
    },
    // 创建任务
    createApp(type, method) {
      if (!this.taskName) {
        this.$Message.warning('请输入任务名!');
        return;
      }
      if (this.upLoadingStatus == 3) {
        this.taskType = '3Dline';
        this.$router.push({
          path: '/create/air-line/3D-line',
          query: {
            taskName: this.taskName,
            taskType: this.taskType,
            task3DModel: this.task3DModel,
            team_id: this.teamID,
            dir_id: this.dirID,
          },
        });
      }
      if (this.upLoadingStatus == 1) {
        this.taskType = 'line';
        this.$router.push({
          path: '/create/air-line/application',
          query: {
            taskName: this.taskName,
            taskType: this.taskType,
            task3DModel: this.task3DModel,
            team_id: this.teamID,
            dir_id: this.dirID,
          },
        });
      } else if (this.upLoadingStatus == 2) {
        this.taskType = 'area';
        this.$router.push({
          path: '/create/air-line/application',
          query: {
            taskName: this.taskName,
            taskType: this.taskType,
            task3DModel: this.task3DModel,
            team_id: this.teamID,
            dir_id: this.dirID,
          },
        });
      }
    },
  },

  mounted() {
    this.teamID = this.config.team_id;
    this.dirID = this.config.dir_id;
    this.taskName = '新建任务' + new Date().toLocaleString();
  },
};
</script> 

<style lang="scss" scoped>
.add-project-box {
  padding: 0 10px 10px;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: $font_color_1;
    margin: 15px 0 10px;
  }
  .margin_bottom {
    margin-bottom: 30px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      width: 136px;
      height: 62px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      transition: all 0.4s ease-in;
    }
    > div:hover {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    }
  }
  /deep/ .ivu-input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    box-shadow: none;
    border-radius: 0;
    font-size: 12px;
    color: #333;
    padding: 0;
  }
  /deep/ .ivu-select-item {
    padding: 7px 16px;
  }
}
</style>